.dashboard_container {
    min-height: 100vh;
    max-width: 100vw;
    padding: 0 1rem;
}

.dash_body {
    /* width: inherit; */
    display: flex;
    max-width: 100vw;
    /* justify-content: space-between; */
    padding: 2rem;
}

.dash_rec_container,
.dash_my_course_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* margin: 2rem 0; */
}

.dash_up_container {
    display: flex;
    flex-wrap: wrap;
    /* box-shadow: inset -75px 0 15px 10px #00000050; */
    z-index: 1;
}

.main_heading {
    text-align: left;
    text-transform: uppercase;
    color: #5e5e5e;
    font-size: 2rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.hello h3 {
    font-size: 2.5rem;
    margin-left: 6rem;
    margin-top: 2rem;
    color: teal;
    text-align: left;
    font-family: "Montserrat", sans-serif;
}

/* Leaderboard css */
.leaderboard_container {
    height: 20rem;
    width: 100%;
    background-color: #95d0d82c;
    padding: 2rem;
    display: flex;
}

.leaderboard_left {
    width: 70%;
}

.leaderboard_line {
    height: 100%;
    width: 1px;
    border: 0.5px dashed #0000005b;
    margin: 0 1rem;
}

.leaderboard_right {
    width: 30%;
    height: 100%;
    /* background-color: #13a177; */
}

.leaderboard_heading {
    text-align: left;
    font-size: 2.2rem;
    font-family: "Montserrat", sans-serif;
    color: #1f1f1f;
}

.leaderboard_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.5rem 0;
}

.report_slider {
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 5px 5px 25px #8bd5e08f;
    /* background-color: #1f1f1f; */
}

.notification_container {
    height: 20rem;
    overflow-y: scroll;
    background-color: #95d0d82c;
    box-shadow: 5px 5px 25px #8bd5e08f;
    border-radius: 10px;
    padding: 2rem;
}

.notification_card_parent {
    display: flex;
    align-items: center;
    margin: 0.5rem;
    padding: 1rem;
    box-shadow: 2px 2px 5px hsla(0, 0%, 0%, 0.075);
    border-radius: 3px;
}

.notification_card_parent h2 {
    color: #5e5e5e;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
}

.notification_circle {
    height: 1rem;
    width: 1rem;
    border-radius: 100px;
    margin-right: 0.8rem;
}

.taskBtn {
    padding: 0.8rem 1.2rem;
    color: #4C8074;
    border: 2px solid #4C8074;
    background-color: white;
    border-radius: 5px;
    font-family: "Kanit", sans-serif;
    margin-left: 2rem;
    font-size: 1.5rem;
    font-weight: 300;
    text-decoration: none;
}

.taskBtn:hover {
    background-color: #f5f5f5;
}

/* .task_progress_heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: "Kanit", sans-serif;
} */

.leaderboard_progress {
    width: 100%;
    height: 1.5rem;
}

.leaderboard_progress_text {
    font-size: 3rem;
    font-family: "Montserrat", sans-serif;
    color: #1f1f1fd9;
}

.data_point_container {
    width: 80%;
    margin: 0 auto;
}

.data_point_parent {
    margin: 0.2rem;
    padding: 0.5rem;
    box-shadow: 2px 2px 5px hsla(0, 0%, 0%, 0.075);
    border-radius: 3px;
}

.data_point_heading {
    text-align: left;
    font-size: 1rem;
    margin-bottom: 0.7rem;
    font-family: "Montserrat", sans-serif;
}

.data_point_icon_parent {
    display: flex;
    align-items: center;
    color: #5e5e5e;
    font-size: 1.2rem;
    font-family: "Montserrat", sans-serif;
}

.das_body_left {
    width: 70%;
}

.das_body_right {
    width: 30%;
}

.activity_container {
    display: flex;
}

.liveSession_container {
    display: flex;
    /* background-color: #1f1f1fd9; */
    justify-content: center;
    flex-wrap: wrap;
}


/* Modal */
.confirmModal_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 15rem;
    width: 35rem;
    background-color: white;
    box-shadow: 1px 1px 3px #bdbdbd;
    border-radius: 10px;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.confirmModal_heading {
    font-size: 1.6rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.confirmModal_button {
    border: none;
    background-color: #4C8070;
    color: white;
    font: 300 1.5vmax "Roboto";
    /* width: 100%; */
    padding: 0.6vmax 1vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px #00000038;
    margin: 1rem;
}
.pymentModal_container_parent{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    height: 100vh;
    width: 100vw;
    background-color: #00000063;
}
.pymentModal_container {
    position: relative;
    /* height: 50rem; */
    width: 35rem;
    background-color: white;
    box-shadow: 1px 1px 3px #bdbdbd;
    border-radius: 10px;
    z-index: 9;
    padding: 2rem;
}
.paymentModal_image{
    background-size: cover;
    height: 16rem;
    border-radius: 1rem;
    padding: 1rem;
}
.paymentModal_heading{
    font-size: 1.8rem;
    font-weight: 400;
    margin: 1rem 0;
}
.paymentModal_price{
    font-size: 1.5rem;
    font-weight: 400;
}
.paymentModal_description{
    margin: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
    /* #4C8070 */
}
.payment_close_btn{
    font-size: 2.5rem;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.paymentModal_btn{
    width: 100%;
    border: none;
    padding: 1rem 0;
    color: white;
    border-radius: 100px;
    background-color: #4C8070;
}
/* ----------------- */

@media (max-width: 998px) {
    .data_point_container {
        width: 100%;
    }

    .activity_container {
        display: flex;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 768px) {
    .dash_up_container {
        justify-content: center;
    }

    .leaderboard_container {
        flex-direction: column;
        height: auto;
    }

    .leaderboard_left {
        width: 100%;
    }

    .leaderboard_right {
        width: 100%;
    }

    .data_point_container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
    }

    .dash_body {
        justify-content: space-between;
    }

    .das_body_right {
        width: 45%;
    }
}

@media screen and (max-width: 500px) {
    .dash_body {
        flex-direction: column;
    }

    .das_body_left,
    .das_body_right {
        width: 100%;
    }

    .liveSession_container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .activity_container {
        justify-content: center;
    }
}