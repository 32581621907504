.collegeDashboard_container {
    background-color: #f6fafd;
    padding: 1rem 10rem;
}
.college_hero_heading, .college_slot_heading{
    margin: 2rem;
}
.slot_email_input{
    margin-top: 2rem;
    width: 100%;
    height: 2.5rem;
    border-radius: 5px;
    border: 1px solid black;
    padding: 1rem;
}
.selectedSlot_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 2rem;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #0000005b;
}
.slot_date{
    text-align: left;
}
.college-container{
    background-color: white;
    padding: 2rem;
    margin: 1rem;
    border-radius: 10px;
    box-shadow: 2px 2px 5px #0000005b;
    display: flex;
}
.college_container_heading{
    text-align: left;
    font-size: 1.8rem;
}
.college_container_name{
    text-align: left;
    margin: 0.7rem 0;
    color: #686868;
    font-weight: 400;
    font-size: 2rem;
}
.college_container_subHeading{
    font-size: 1.5rem;
    font-weight: 500;
    color: #666666;
}

.slot_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.book_slot {
    width: 100%;
    background-color: #0080ff;
    border: none;
    color: white;
    padding: 1rem;
    margin: 2rem 0;
    border-radius: 5px;
    cursor: pointer;
}