.privacy_heading{
    text-align: left;
    font-size: 1.8rem;
    line-height: 3.5rem;
    margin: 1rem 3rem;
}

.privacy_subHeading{
    text-align: left;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 1rem 3rem;
    color: gray;
}
.privacy_subHeading_child{
    text-align: left;
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin: 1rem 5rem;
    color: gray;
}