.news_card{
    background: #FFFFFF;
    border-radius: 2rem;
    border: 0.1rem  solid #EBEDF4;
    margin: 1rem;
    padding: 1rem;
    height: 28rem;
    box-shadow: 2px 2px 7px 0.5px #7c7c7c8a;
    overflow: hidden;
    text-overflow: ellipsis;
}
.news_card_img{
    background-size: cover;
    width: 100%;
    height: 16rem;
    border-radius: 1rem;
}
.news_card_details{
    display:flex;
    flex-direction: column;
}
.news_info{
    font-size: 2rem;
    color: #000000;
}
.news_info p{
    display: -webkit-box;
    /* max-width: 200px; */
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 2rem;
}
.news_time p{
    font-size: 1rem;
    color:#434444;
}