/* Open Sans font */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
/* font-family: 'Open Sans', sans-serif; */

/* Raleway font */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap'); */
/* font-family: 'Raleway', sans-serif; */

/* Roboto flex */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
/* font-family: "Roboto Flex", sans-serif; */

/* Montserrat font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/* font-family: 'Montserrat', sans-serif; */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Style for scrollbar */
::-webkit-scrollbar{
  width: 10px;
}
::-webkit-scrollbar-track{
  /* border-radius: 10px; */
  background: #0f86931e;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: linear-gradient(to left, #fff, #cccccc);
  border: 1px solid #979797;
}

/* Style for typography */
p{
  font-family: "Roboto Flex", serif;
  color: rgba(82, 80, 80, 0.692);
  
}
h1,h2,h3, a{
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
}
h4,h5,h6{
  font-family: "Roboto Flex", serif;
  font-weight: 500;
  /* text-align: center; */
}

/* input type style */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input{
  outline: none;
}
html{
  font-size: 62.5%;
}

@media screen and (max-width: 998px) {
  html{
    font-size: 55%;
  }
}

@media (max-width: 768px) {
  html{
    font-size: 50%;
  }
}
@media (max-width: 400px) {
  html{
    font-size: 45%;
  }
}