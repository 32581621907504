.dashboard-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f4;
}

/* Filter Menu */
.filter-menu {
  background: white;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 15px;
}

.filter-row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.filter-row select{
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.remove-button {
  background: #ffcccc;
  border: none;
  cursor: pointer;
  padding: 5px 8px;
  border-radius: 50%;
}

.remove-button:hover {
  background: #ff9999;
}


.users-table-parent {
  height: 70vh;
  overflow: scroll;
  padding: 0;
}

.users-table {
  /* width: 100%; */
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  /* overflow-y: scroll; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.users-table th,
.users-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* width: 40rem; */
}

.users-table th {
  background-color: #f1f1f1;
  font-weight: bold;
  position: static;
  z-index: 9999;
  /* right: 0; */
}

.users-table tr:hover {
  background-color: #f9f9f9;
}

.update-button,
.delete-button {
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.update-button {
  background-color: #4caf50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}




.pagination {
  list-style: none;
  /* height: 31.5px; */
  /* width: 31.5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
  /* background-color: #4caf50; */
}

.active {
  background-color: #1e50ff;
  border-radius: 50%;
}

.page-item {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}