.login_container {
  display: flex;
  /* align-items: center; */
  padding: 2rem;
}

.login_left {
  /* background-color: #4C8074; */
  border-radius: 5px;
  background-image: url('../../images/Learning_Icon.png');
  background-position: center;
  background-size: cover;
  height: 94vh;
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_left_image {
  /* background-image: url('../../images/Learning_Icon.png'); */
  background-position: center;
  background-size: cover;
  height: 20rem;
  width: 20rem;
}

.login_right {
  height: 75vh;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_logo {
  background-image: url('../../images/logo.png');
  background-position: center;
  background-size: cover;
  height: 10rem;
  width: 23rem !important;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  height: 90%;
  width: 35vw;
  transition: all 0.5s;
}

.loginForm>div {
  display: flex;
  width: 100%;
  align-items: center;
}

.loginForm>div>input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax cursive;
  outline: none;
}

.loginForm>div>svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.loginForm>a {
  color: rgba(0, 0, 0, 0.651);
  text-decoration: none;
  align-self: flex-end;
  transition: all 0.5s;
  font: 500 0.8vmax "Gill Sans";
}

.loginForm>a:hover {
  color: black;
}

.loginBtn {
  border: none;
  background-color: #4C8070;
  color: white;
  font: 300 0.9vmax "Roboto";
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.loginBtn:hover {
  background-color: #79b09f;
}

@media screen and (max-width: 650px) {
  .login_left {
    display: none;
  }

  .login_right {
    width: 100%;
  }

  .loginForm {
    width: 60vw;
    padding: 0;
  }
  .loginForm>div>input {
    font: 300 2.5vmax normal;
    padding: 1vmax 5vmax;
  }
  .loginForm>div>svg {
    font-size: 2.6vmax;
  }
  .loginForm>a {
    font: 500 2.2vmax "Gill Sans";
  }
  .loginBtn{
    font: 300 2.5vmax "Roboto";
  }
}

@media screen and (max-width: 450px) {
  .loginForm {
    width: 80vw;
  }
  .loginForm>div>input {
    font: 300 2.8vmax normal;
    padding: 1vmax 5vmax;
  }
  .loginForm>div>svg {
    font-size: 2.8vmax;
  }
  .loginForm>a {
    font: 500 2.4vmax "Gill Sans";
  }
  .loginBtn{
    font: 300 2.7vmax "Roboto";
  }
}