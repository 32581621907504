.enrollModal_container{
    height: 40vh;
    width: 40vw;
    background-color: #eaeaea;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: 1px 1px 15px #cbcbcb;
}