.onboarding_container {
    display: flex;
    /* align-items: center; */
    padding: 2rem;
}

.onboarding_left {
    border-radius: 5px;
    background-image: url('../../images/Learning_Icon.png');
    background-position: center;
    background-size: cover;
    height: 94vh;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.verify_parent{
    position: relative;
}
.verify_btn {
    position: absolute;
    right: 0.4rem;
    padding: 0.4rem 1.2rem;
    background-color: #4C8070;
    border-radius: 0.5rem;
    border: none;
    color: white;
    cursor: pointer;
    box-shadow: 0 3px #4795e23d;
    font-size: 1.2rem;
}

@media screen and (max-width: 998px) {
    .verify_btn{
        font-size: 1.5rem;
    }
}