.liveSessionCard_container {
    height: 15rem;
    width: 15rem;
    margin: 1rem;
    background-color: #f8f8f8;
    border-radius: 2rem;
    border: 0.1rem solid #EBEDF4;
    padding: 1rem;
    margin: 1.5rem 1rem;
    transition: transform 150ms ease-in-out;
    box-shadow: 1px 1px 10px 0px #cfcfcf;
    cursor: pointer;
}

.liveSessionCard_container:hover {
    transform: translateY(-15px);
    background: #ffffffef;
    box-shadow: 2px 2px 20px 5px #cfcfcf;
}

.liveSessionCard_image {
    background-size: cover;
    height: 10rem;
    border-radius: 1rem;
    padding: 1rem;
}

.liveSessionCard_name {
    font-size: 1.2rem;
    text-align: center;
    font-weight: 500;
    color: black;
    padding: 1rem 0;
}