.sidebar {
    width: 100%;
    min-height: 100vh;
    background-color: #333;
    color: white;
    padding: 15px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
    margin-top: 0;
}

.sidebar .adminNavbar_links {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.sidebar .adminNavbar_links .adminNavbar_link {
    margin: 1rem 0;
    cursor: pointer;
    font-size: 1.5rem;
    color: aqua;
    background-color: transparent;
    border: none;
    text-decoration: none;
}

.sidebar .adminNavbar_links .adminNavbar_link:hover {
    text-decoration: underline;
}

@media (min-width: 768px) {
    .sidebar {
        width: 250px;
    }
}