.forgotPassword_container {
    display: flex;
    /* align-items: center; */
    padding: 2rem;
}

.forgotpassword_left {
    border-radius: 5px;
    background-image: url('../../images/Learning_Icon.png');
    background-position: center;
    background-size: cover;
    height: 94vh;
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: center;
}