.CourseLandingPage_container {
    max-width: 100vw;
}

/* Course Hero */
.course_hero {
    position: relative;
    height: 90vh;
    background-image: url('./iot-landing-page.jpg');
    background-position: center;
    background-size: cover;
}

.course_hero_overlay {
    background-color: #00000073;
    height: inherit;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.course_hero_heading {
    font-size: 5rem;
    color: white;
    font-weight: 400;
}

.course_hero_para {
    width: 70%;
    font-size: 2rem;
    color: white;
    font-weight: 500;
    margin: 3rem 0;
}

.course_hero_btn {
    padding: 1rem 1.2rem;
    background-color: #148492;
    border: none;
    border-radius: 5px;
    color: white;
    text-decoration: none;
    align-self: flex-start;
}

/* About Course */
.course_about {
    display: flex;
    height: 80vh;
    background-color: #ebf2fa;
    padding: 2rem;
}

.course_about_left {
    width: 50%;
}

.course_about_right {
    width: 50%;
    padding: 3rem;
}

.course_about_left_heading {
    text-align: left;
    font-size: 3rem;
    margin: 3rem 0;
    font-weight: 900;
}

.course_about_left_para {
    text-align: justify;
    font-size: 1.6rem;
    line-height: 2.7rem;
    font-weight: 400;
    width: 90%;
}

.about_details_box {
    display: flex;
    align-items: center;
    margin: 2.5rem 0;
}

.details_icon {
    color: #148492;
    font-size: 3.5rem;
}

.about_details_box>h1 {
    font-size: 1.3rem;
    margin-left: 1rem;
    color: gray;
}

/* Pre-requisites */
.course_PreRequisite {
    background-color: #ebf2fa;
}

/* Course Outcomes */
.course_outcomes {
    background-color: #ebf2fa;
}

/* Course instructor */
.course_instructor {
    height: 100vh;
    padding: 2rem;
}

.course_instructor_heading {
    font-size: 4rem;
    font-weight: 900;
    margin: 5rem 0;
}

.course_instructor_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 80%;
}

.course_instructor_box_left {
    width: 50%;
    height: 100%;
}

.course_instructor_img {
    height: 100%;
    width: 90%;
    background-image: url('./instructor.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.course_instructor_box_right {
    width: 50%;
}

.course_instructor_designation {
    text-align: left;
    margin-bottom: 0.5rem;
}

.instructor_name {
    text-align: left;
    margin-bottom: 1rem;
}

.instructor_description {
    font-size: 1.8rem;
    line-height: 2.8rem;
    font-weight: 300;
}

/* Course curriculum */
.course_curriculum {
    height: 100vh;
    display: flex;
    margin: 5rem;
}

.course_curriculum_left {
    width: 50%;
}

.course_curriculum_heading {
    text-align: left;
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 3rem;
}

.course_module_heading {
    text-align: left;
}

.course_module_para {
    font-size: 1.5rem;
    font-weight: 400;
    color: black;
    margin: 2rem 0;
    line-height: 3rem;
    width: 80%;
}

.course_curriculum_right {
    width: 50%;
}

.course_curriculum_img {
    width: 90%;
    height: 90%;
    background-image: url('./iot-image.jpg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}